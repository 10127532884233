import React, {HTMLProps, Props} from 'react'

import './index.css'

/*interface IButtonProps = {

}*/

interface IButton extends HTMLProps<any> {
  size?: any;
  disable?: any;
}

const Button = (props: IButton) => {
  const {children, size = "normal", className, onClick, disable} = props;
  return (
    <button
      onClick={onClick}
      disabled={disable}
      className={`
                btn
                ${size === "sm" ? 'btn-sm' : 'btn-normal'}
                ${disable ? 'btn-disabled' : ''}
                text-base 
                font-semibold 
                focus:outline-none
                ${className}
        `}
      style={props.style || {}}
    >
      {children}
    </button>
  )
}

export default Button;
