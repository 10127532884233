import React, {useState, useEffect} from 'react';
import {Link, withRouter} from "react-router-dom";

import {SOCIAL_URL_DISCORD, SOCIAL_URL_INSTAGRAM, SOCIAL_URL_OPENSEA, SOCIAL_URL_TELEGRAM, SOCIAL_URL_TWITTER} from "../../config";

const Footer = ({history}: { history: any }) => {

  return (
    <div className="footer text-white">
      <div className="px-6 sm:px-16 py-12">
        <div className="footer-content flex flex-wrap justify-between">
          <div>
            <Link to='/' className='mr-5 xs:mr-12'>
              <img src="/assets/img/logo/logo.png" width={89} height={55}/>
            </Link>
            <p>© {new Date().getFullYear()} S2TM. All rights reserved.</p>
            {/*<div className="flex space-x-6 items-start mt-4">
              <a href="/doc/Privacy Policy.pdf" target="_blank" className="">Privacy Policy</a>
              <a href="/doc/Terms of Service.pdf" target="_blank" className="">Terms of Service</a>
            </div>*/}
          </div>

          <div className='social-links flex self-end -mx-4 mt-4 sm:mt-0'>
            <a className="social-link pb-4 md:pb-0 px-4" href={SOCIAL_URL_TWITTER} target="_blank">Twitter</a>
            {/*<a className="social-link pb-4 md:pb-0 px-4" href={SOCIAL_URL_INSTAGRAM} target="_blank">Instagram</a>*/}
            {/*<a className="social-link pb-4 md:pb-0 px-4" href={SOCIAL_URL_OPENSEA} target="_blank">OpenSea</a>*/}
            <a className="social-link pb-4 md:pb-0 px-4" href={SOCIAL_URL_DISCORD} target="_blank">Discord</a>
            {/*<a className="social-link pb-4 md:pb-0 px-4" href={SOCIAL_URL_TELEGRAM} target="_blank">Telegram</a>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Footer);

