import _ from 'lodash';

import {getLocalData, setLocalData} from "./LsUtil";
import {CT_METAMAST, LS_KEY_AUTH} from "../constants";

/**
 * Login action.
 * Actually save the networkId, Account, ConnectType on local storage.
 *
 * @param netId
 * @param account
 * @param connectType
 */
export function doLogin(netId: number, account: string, connectType: string) {
  setLocalData(LS_KEY_AUTH, {
    [netId]: {
      [connectType || CT_METAMAST]: {
        [account]: new Date().getTime()
      }
    }
  });
}

export function doLogout(netId: number, account: string, connectType: string) {
  setLocalData(LS_KEY_AUTH, {
    [netId]: {
      [connectType || CT_METAMAST]: {
        [account]: null
      }
    }
  });
}

export function isLoggedIn(netId: number | null, account: string, connectType: string) {
  const lsData = getLocalData(LS_KEY_AUTH, null);
  if (_.get(lsData, `[${netId}][${connectType || CT_METAMAST}][${account}]`)) return true;
  else return false;
  return true;
}

export function getLastLogin(netId: number) {
  const lsData = getLocalData(LS_KEY_AUTH, null);
  return _.get(lsData, `[${netId}]`);
}

