const ConnectToInjected = async () => {
  let provider = null;
  // @ts-ignore
  if (typeof window.ethereum !== 'undefined') {
    // @ts-ignore
    provider = window.ethereum;
    try {
      await provider.request({method: 'eth_requestAccounts'})
    } catch (error) {
      throw new Error("User Rejected");
    }
  } else {
    // @ts-ignore
    if (window.web3) {
      // @ts-ignore
      provider = window.web3.currentProvider;
    } else {
      throw new Error("No Web3 Provider found");
    }
  }
  return provider;
};

export default ConnectToInjected;
