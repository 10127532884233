import React, {useState, useEffect, useCallback, useLayoutEffect} from 'react';
import {BrowserRouter, Switch, Route, useLocation, Redirect} from 'react-router-dom';
import {connect, useDispatch, useSelector} from "react-redux";
import Web3 from "web3";
import _ from "lodash";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from "axios";

import Web3Util from "./dnweb3/helpers/Web3Util";
import {IStateType} from "./redux/auth/reducer";
import ConnectToInjected from "./dnweb3/connectors/injected";
import ConnectToWalletConnect from "./dnweb3/connectors/walletconnect";
import {setLocalData} from "./dnweb3/helpers/LsUtil";


import {
  setAddress,
  setConnectType,
  setError,
  setInitData, setLoading,
  setLoggedIn,
  setNetworkId,
  setStateData,
} from "./redux/auth/actions";
import {APP_API} from "./config";

import {doLogin, doLogout, getLastLogin, isLoggedIn} from "./dnweb3/helpers/AuthUtil";
import {BSC_CHAIN_ID, CT_METAMAST, CT_WALLET_CONNECT, GANACHE_CHAIN_ID, MAINNET_CHAIN_ID, MSG_SELECT_BINANCE, MSG_SELECT_GANACHE, MSG_SELECT_MAINNET, MSG_SELECT_RINKEBY_NET, RINKEBY_CHAIN_ID} from "./dnweb3/constants";
import {APP_NETWORK_ID} from "./dnweb3/config";

import 'react-notifications/lib/notifications.css';
import './App.scss';


import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer/index';
import NavbarMobile from './components/Navbar/NavbarMobile';
import {IAppDispatchProps, IMapStateToProps} from "./types";
import LoadingData from "./components/LoadingData";
import AppApi from "./dnweb3/appapi";

import ReactGA from 'react-ga';
import {scrollToElement} from "./dnweb3/helpers/utilities";
import ConnectToGanacheInjected from "./dnweb3/connectors/ganacheConnect";

ReactGA.initialize('UA-192747559-1'); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);

// Axios Initialization
axios.defaults.baseURL = APP_API;
axios.defaults.headers.common['Accept'] = '*/*';
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';


const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const curNetworkId = useSelector((state: IMapStateToProps) => state.authUser.networkId);
  const connectType = useSelector((state: IMapStateToProps) => state.authUser.connectType);
  const address = useSelector((state: IMapStateToProps) => state.authUser.address);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useLayoutEffect(() => {
    if (location.hash) {
      const ele = (document.getElementById(location.hash.substr(1)) as HTMLElement);
      scrollToElement(ele);
    }
  }, [location]);

  useEffect(() => {
    // Navbar
    const hideMenu = () => {
      if (window.innerWidth >= 768 && isOpen) {
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', hideMenu);

    // init
    // event binding...
    dispatch(setStateData({
      onConnect: onConnect,
      killSession: resetApp,
    }));

    const lastLogin = getLastLogin(APP_NETWORK_ID);
    if (lastLogin && _.isObject(lastLogin)) {
      for (let connectType in lastLogin) {
        if (connectType == CT_METAMAST || connectType == CT_WALLET_CONNECT) {
          (async () => {
            await onConnect(connectType, true, null);
          })();
          break;
        }
      }
    }

    return () => {
      window.removeEventListener('resize', hideMenu);
    };
  }, []);

  const resetApp = async (web3: Web3, networkId: number, address: string, connectType: string) => {
    console.log("Resetting APP", networkId, address, connectType);
    if (!networkId) return;
    console.log("Resetting APP");
    doLogout(networkId, address, connectType);

    // @ts-ignore
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      // @ts-ignore
      await web3.currentProvider.close();
    }
    // clearCachedProvider;
    setLocalData(CT_WALLET_CONNECT, null);

    dispatch(setInitData());
    dispatch(setStateData({
      loggedIn: false,
      connected: false,
      address: '',
      chainId: null,
      networkId: null,
      web3: null,
      provider: null,
    }));
  };

  const onConnect = async (connectType: string = '', isCached: boolean, cb?: any) => {
    console.log("\nOn Connect", connectType, isCached);

    let provider = null;
    let web3 = null;
    let connected = false;
    let loggedIn = false;
    let networkId = null;
    let chainId = null;
    let address = null;
    let accounts = null;
    dispatch(setLoading(true));

    try {
      switch (connectType) {
        case CT_WALLET_CONNECT:
          provider = ConnectToWalletConnect({chainId: APP_NETWORK_ID});
          // if (isCached) {
          await provider.enable().catch((e) => {
            // console.log(e);
            dispatch(setLoading(false));
          });
          // }
          break;
        default:
          if (APP_NETWORK_ID == GANACHE_CHAIN_ID) {
            console.log("---ganache");
            provider = await ConnectToGanacheInjected();
          } else
            provider = await ConnectToInjected();
          break;
      }


      // @ts-ignore
      web3 = Web3Util.initWeb3(provider);
      await subscribeProvider(web3, provider);

      networkId = await web3.eth.net.getId();
      accounts = await web3.eth.getAccounts();
      // console.log(accounts);
      address = accounts[0];
      chainId = await web3.eth.getChainId();
    } catch (e) {
      console.error("Error -> ", e);
      dispatch(setLoading(false));
    }

    console.log("App NetworkId:", APP_NETWORK_ID, " NetId:", networkId, " ChainId:", chainId, "Address:", address);
    if (networkId && networkId != APP_NETWORK_ID) {
      if (!isCached) {
        if (APP_NETWORK_ID == BSC_CHAIN_ID)
          NotificationManager.info(MSG_SELECT_BINANCE);
        else if (APP_NETWORK_ID == RINKEBY_CHAIN_ID)
          NotificationManager.info(MSG_SELECT_RINKEBY_NET);
        else if (APP_NETWORK_ID == MAINNET_CHAIN_ID)
          NotificationManager.info(MSG_SELECT_MAINNET);
        else if (APP_NETWORK_ID == GANACHE_CHAIN_ID)
          NotificationManager.info(MSG_SELECT_GANACHE);
      }
    }
    // According to cache, we set the login status.
    if (networkId && address && connectType) {
      if (networkId == APP_NETWORK_ID) {
        if (isCached) {
          loggedIn = isLoggedIn(networkId, address, connectType);
        } else {
          loggedIn = true;
          doLogin(networkId, address, connectType);
        }
      }
    }

    // Set state data.
    dispatch(setStateData({
      loggedIn,
      connected,
      networkId,
      address,
      chainId,
      web3,
      provider,
      connectType,
      loading: false,
      triedConnect: true,
    }));

    if (cb && loggedIn) {
      cb();
    }
  };

  const subscribeProvider = async (web3: Web3, provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => {
    });
    provider.on("accountsChanged", async (accounts: string[]) => {
      await accountsChanged(web3, accounts);
    });
    provider.on("chainChanged", async (chainId: number) => {
      await chainChanged(web3, chainId);
    });

    provider.on("networkChanged", async (networkId: number) => {
      await networkChanged(web3, networkId);
    });
  };

  const accountsChanged = async (web3: Web3, accounts: string[]) => {
    await dispatch(setStateData({address: accounts[0]}));
    await checkLogin();
  };

  const chainChanged = async (web3: Web3, chainId: any) => {
    const networkId = await web3.eth.net.getId();
    await dispatch(setStateData({chainId: parseInt(chainId), networkId}));
    await checkLogin();
  };

  const networkChanged = async (web3: Web3, networkId: any) => {
    // @ts-ignore
    const chainId = await web3.eth.chainId();
    await dispatch(setStateData({chainId, networkId: parseInt(networkId)}));
    await checkLogin();
  };

  const checkLogin = useCallback(async () => {
    // Login status checking
    if (!isLoggedIn(curNetworkId, address, connectType)) {
      dispatch(setStateData({
        loggedIn: false,
      }));
    }
  }, [dispatch, curNetworkId, address, connectType]);

  return (
    <>
      <div className={`page${location.pathname == '/' ? ' landing' : ''}`}>
        <LoadingData/>
        <Navbar isOpen={isOpen} toggle={toggle}/>
        <NavbarMobile isOpen={isOpen} toggle={toggle}/>

        <Switch>
          <Route exact path='/'>
            <Home/>
          </Route>
        </Switch>

        <Footer/>
      </div>
      <NotificationContainer/>
    </>
  );
}


const mapStateToProps = (state: IMapStateToProps): IStateType => {
  return {...state.authUser};
}

const mapDispatchToProps = (dispatch: any): IAppDispatchProps => {
  return {
    setAddressRequest: (address: string) => dispatch(setAddress(address)),
    setNetworkIdRequest: (networkId: number) => dispatch(setNetworkId(networkId)),
    setConnectTypeRequest: (connectType: string) => dispatch(setConnectType(connectType)),
    setErrorRequest: (error: any) => dispatch(setError(error)),
    setInitData: () => dispatch(setInitData()),
    setLoggedIn: (status: boolean) => dispatch(setLoggedIn(status)),
    setStateData: (data: any) => dispatch(setStateData(data)),
    setLoading: (data) => dispatch(setLoading(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
