import BigNumber from "bignumber.js";
import {bnZero} from "../helpers/utilities";

export interface IMintingData {
  price: BigNumber
  mintLimit: number

  nftSold: number
  nftTotal?: number
  saleActive: boolean
  baseUri?:string
}

export const InitialMintingData: IMintingData = {
  nftSold: 0,
  mintLimit: 10,
  price: bnZero(),
  saleActive: true,
  nftTotal: 10000,
};


