export const ETH_STANDARD_PATH = "m/44'/60'/0'/0";
export const ENTROPY_KEY = "ENTROPY";
export const MNEMONIC_KEY = "MNEMONIC";

export const DEFAULT_ACTIVE_INDEX = 0;
export const MAINNET_CHAIN_ID = 1;
export const ROPSTEN_CHAIN_ID = 3;
export const RINKEBY_CHAIN_ID = 4;
export const GOERLI_CHAIN_ID = 5;
export const KOVAN_CHAIN_ID = 42;
export const BSC_CHAIN_ID: number = 56;
export const BSC_TEST_NET_CHAIN_ID: number = 97;
export const GANACHE_CHAIN_ID = 5777;
export const DEFAULT_CHAIN_ID = BSC_CHAIN_ID;

/* loading types */
export const LOADING_TYPE_BUYING = 1;
export const LOADING_TYPE_WITHDRAW = 2;
export const LOADING_TYPE_FETCH_POOL_DATA = 3;
export const LOADING_TYPE_WITHDRAW_CLOUT = 4;
export const LOADING_TYPE_WAIT = 100;

/* Local storage key */
export const LS_KEY_AUTH = `${process.env.REACT_APP_LS_KEY_AUTH}`;
export const LS_KEY_LAST_ID = `${LS_KEY_AUTH}_LAST_ID`;

// todo
export const TIMER_INTERVAL = 180;   // second unit

export const ALCHEMY_API_ENDPOINTS = {
  [GANACHE_CHAIN_ID]: "http://127.0.0.1:7545",
  [RINKEBY_CHAIN_ID]: "https://eth-rinkeby.alchemyapi.io/v2/%API_KEY%",
  [BSC_CHAIN_ID]: "https://eth-rinkeby.alchemyapi.io/v2/%API_KEY%",
}

