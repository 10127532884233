import React, {useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {doLogin, doLogout, isLoggedIn} from "../dnweb3/helpers/AuthUtil";
import {setLoggedIn, setStateData} from "../redux/auth/actions";
import {NotificationManager} from 'react-notifications';

import {BSC_CHAIN_ID, BSC_TEST_NET_CHAIN_ID, MAINNET_CHAIN_ID, MSG_SELECT_MAINNET, MSG_SELECT_RINKEBY_NET, RINKEBY_CHAIN_ID, TIMER_INTERVAL} from "../dnweb3/constants";

import {APP_NETWORK_ID} from "../dnweb3/config";
import SLoadingModal from "./Dialog/SLoadingModal";
import {IMapStateToProps} from "../types";

import useMintingDataUpdater from "../dnweb3/hooks/useMintingDataUpdater";


/**
 * A loading component with the main biz logic to fetch data
 * Polling by timer. Default interval is 60s
 *
 * @returns {*}
 * @constructor
 */
export default function LoadingData() {
  const dispatch = useDispatch();

  const {
    authUser: {
      loading,
      loadingType,
      connectType,
      networkId: curNetworkId,
      address,
      loggedIn,
      web3
    }
  } = useSelector((state: IMapStateToProps) => state);

  // Fetching public data
  const {loading: loadingMintingData, updater: updateMintingData} = useMintingDataUpdater();

  /**
   * Real time login status
   */
  /*useEffect(() => {
    // todo: Please remove this
    const func = async () => {
      if (web3 && address) {
        const dataToSign = `I am signing my one-time nonce: ${123}`;
        web3.eth.personal.sign(dataToSign, address, '', [(a: any, b: any) => {
          console.log(a, b);
        }]).then((res: string) => {
          console.log("sign result: ", res);
          // web3.eth.personal.ecRecover(dataToSign, res).then(console.log);
        });
      }
      return null;
    }
    // const response = func();
  }, [web3, address]);*/


  useEffect(() => {
    // Already logged in?
    if (isLoggedIn(curNetworkId, address, connectType)) {
      dispatch(setLoggedIn(true));
    }

    fetchPublicData();
    const interval = setInterval(async () => {
      fetchPublicData();
    }, TIMER_INTERVAL * 1000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (curNetworkId && address && connectType) {
      if (loggedIn) doLogin(curNetworkId, address, connectType);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (curNetworkId && curNetworkId != APP_NETWORK_ID) {
      // @ts-ignore
      if (APP_NETWORK_ID == MAINNET_CHAIN_ID)
        NotificationManager.info(MSG_SELECT_RINKEBY_NET);
      else if (APP_NETWORK_ID == RINKEBY_CHAIN_ID)
        NotificationManager.info(MSG_SELECT_MAINNET);
    }
  }, [curNetworkId]);

  useEffect(() => {
    if (loggedIn && address && curNetworkId) {

    }
  }, [loggedIn, address, curNetworkId]);


  useEffect(() => {
    const loginOk = isLoggedIn(curNetworkId, address, connectType);
    if (loginOk && !loggedIn) {
      dispatch(setLoggedIn(true));
    }
  }, [loggedIn, address, curNetworkId, connectType]);

  /**
   * Update NFT lists
   * @deprecated
   */
  const fetchData = useCallback(async () => {
    if (address && loggedIn) {
      // updateUserNftIdList();
      // GetTokenIdsByHolder({variables: {account: address}});
      // GetTokenIdsByHolder({variables: {account: '0xe487769A3C6e6E283eE224977864a92BE2C301B8'}});
    }
  }, [dispatch, address, loading, loggedIn]);


  /**
   * Update NFT minting data
   */
  const fetchPublicData = useCallback(async () => {
    updateMintingData();
  }, [dispatch]);

  return (
    <><SLoadingModal isOpen={loading && loadingType > 0}/></>
  );
};
