import _ from "lodash";
import BigNumber from "bignumber.js";
import abiMaster from "../abi/S2TMPass.json";

import {NetAddressMap} from "../constants";
import {APP_NETWORK_ID} from "../config";
import {Contract} from "web3-eth-contract";
import {INFT} from "../types";
import {bnDivdedByDecimals, bnToDec, bnZero, decToBn} from "../helpers/utilities";
import Web3 from "web3";
import {IMintingData, InitialMintingData} from "../types/mintDataTypes";

export class ContractController {
  protected web3: Web3 | undefined;
  public masterSM;

  public networkId: number;
  public address: string | undefined;

  public constructor(web3: Web3) {
    this.web3 = web3;
    this.networkId = APP_NETWORK_ID;
    this.masterSM = this.master();
  }

  public master(): Contract {
    const tokenAddress = _.get(NetAddressMap, `[${this.networkId}][MASTER]`);
    if (tokenAddress) {
      // @ts-ignore
      return this.web3 ? new this.web3.eth.Contract(abiMaster, tokenAddress) : null;
    } else {
      throw new Error(`Unknown token for network ${this.networkId}`);
    }
  }

  /**
   * ========================================================================================
   * Biz logic methods
   * ========================================================================================
   */
  public async getNftList(totalCount: number): Promise<Array<INFT>> {
    // console.log("C->NFT List");
    const arr: Array<INFT> = [];
    for (let packId = 0; packId < totalCount; packId++) {

      /*const res = await this.masterSM.methods.NFTTypeMap(NFT_LIST[packId].typeId).call();
      // console.log(res);
      const nft: INFT = {
        count: parseInt(res.count),
        minted: parseInt(res.minted),
        price: new BigNumber(res.price),
        tokenURI: res.tokenURI,
        typeId: parseInt(res.typeId),
        description: "",
        image: "",
        name: "",
        saturnaAmount: new BigNumber(res.saturnaAmount),
        startTimestamp: parseInt(res.startTimestamp),
        endTimestamp: parseInt(res.endTimestamp),
        priceUsd: bnZero()
      }*/

      // Fetch token URI
      /*await fetch(nft.tokenURI)
        .then(response => response.json())
        .then(data => {
          nft.image = data.image;
          nft.name = data.name;
          nft.description = data.description;
        });*/

      // arr.push(nft);
    }
    return arr;
  };

  public async getUserNftIdList(address: string, typeId: number, totalCount: number): Promise<object> {
    console.log("C->User NFT Id List");
    let map = {};
    return map;
    if (typeId >= 0) {
      const ids = await this.masterSM.methods.getIDFromMap(address, typeId).call();
      // @ts-ignore
      map[typeId] = ids;
    } else {
      for (let i = 0; i < totalCount; i++) {
        // const ids = await this.masterSM.methods.getIDFromMap(address, NFT_LIST[i].typeId).call();
        // @ts-ignore
        // map[i] = ids;
        map[i] = [-1];
      }
    }
    return map;
  };

  public async getBaseTokeUri(): Promise<string> {
    if (!this.web3) return "";
    const res = await this.masterSM.methods.baseURI().call();
    return res;
  };

  public async getUserBalance(address: string): Promise<BigNumber> {
    if (!this.web3) return bnZero();
    // const res = await this.masterSM.methods.balanceOf(address).call();
    const res = await this.web3.eth.getBalance(address);
    return new BigNumber(res);
  };

  /**
   *
   * @param count
   */
  public async getPrice(count: number): Promise<BigNumber> {
    if (!this.web3) return bnZero();
    const res = await this.masterSM.methods.mintPrice().call();
    return new BigNumber(res).multipliedBy(count);
  };

  public async buyMultiple(address: string, qty: number, totalPrice: BigNumber): Promise<any> {
    if (!this.web3) return null;

    const res = await this.masterSM.methods.buyPass(qty).send({
      from: address,
      value: totalPrice.toString(),
    });
    return res;
  };


  public async nameNFT(address: string, totalPrice: BigNumber, tokenId: number, name: string): Promise<any> {
    if (!this.web3) return null;

    const res = await this.masterSM.methods.nameNFT(tokenId, name).send({
      from: address,
      value: totalPrice.toString(),
    });
    return res;
  };

  public async getMintingData(address: string): Promise<IMintingData> {
    const [price, saleActive, mintLimit, nftTotal, totalSupply] = await Promise.all([
      this.getPrice(0),
      this.masterSM.methods.saleActive().call(),
      this.masterSM.methods.mintLimit().call(),
      this.masterSM.methods.supplyLimit().call(),
      this.masterSM.methods.totalSupply().call(),
    ]);
    const ret: IMintingData = InitialMintingData;
    ret.mintLimit = parseInt(mintLimit);
    ret.price = new BigNumber(price);
    ret.nftSold = parseInt(totalSupply);
    ret.saleActive = saleActive;
    ret.nftTotal = nftTotal;
    return ret;
  }

}

export function getContract(web3: Web3) {
  return new ContractController(web3);
}
