import {CT_METAMAST, CT_WALLET_CONNECT} from "./connectType";

export const AppWallets = [
  {
    id: CT_METAMAST,
    name: "Metamask",
  },
  /*{
    id: CT_WALLET_CONNECT,
    name: "WalletConnect",
  },*/
];
