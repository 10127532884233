import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {IMapStateToProps} from "../../types";
import SAccountModal from "../Dialog/SAccountModal";
import {SConnectModal} from "../Dialog/SConnectModal";
import ImgMetamask from "../../assets/images/icon/metamask-i.png";
import {ellipseAddress} from "../../dnweb3/helpers/utilities";
import BtnConnectWallet from "../../assets/images/button/btn-connect-anim.png";

const useNavbar = (isOpen: boolean) => {
  const [visibleConnectModal, setVisibleConnectModal] = useState<boolean | undefined>();
  const location = useLocation();
  const loggedIn = useSelector((state: IMapStateToProps) => state.authUser.loggedIn);
  const address = useSelector((state: IMapStateToProps) => state.authUser.address);

  const renderAccountModals = useCallback(() => {
    return (loggedIn && address) ?
      <SAccountModal isOpen={visibleConnectModal} onClose={() => setVisibleConnectModal(false)}/> :
      <SConnectModal isOpen={visibleConnectModal} onClose={() => setVisibleConnectModal(false)}/>
  }, [isOpen, loggedIn, address, visibleConnectModal, setVisibleConnectModal]);

  const renderConnectButton = useCallback((style?: number) => {
    let html = null;
    switch (style) {
      case 1:
        html = !(loggedIn && address) && (<>
            <div className="btn-anim uppercase mt-9 md:mt-0 mx-auto md:mx-0 md:ml-auto cursor-pointer uppercase inline-block"
                 onClick={() => setVisibleConnectModal(true)}
            >
              Connect Wallet
            </div>
          </>
        );
        break;
      default:
        html = (
          <div className="hover:opacity-95 ml-auto mr-4 md:mr-0 md:ml-4 flex items-center self-center">
            <img className="mr-2" src={ImgMetamask} width={21} height={18.34}/>
            <a
              className="nav uppercase my-4"
              href='#'
              onClick={e => {
                // @ts-ignore
                e.preventDefault();
                setVisibleConnectModal(true);
              }}
            >{loggedIn && address ? ellipseAddress(address, 6) : "Connect wallet"}</a>
          </div>);
        break;
    }
    return html;
  }, [isOpen, loggedIn, address, visibleConnectModal, setVisibleConnectModal]);

  return {
    loggedIn,
    address,
    renderConnectButton,
    renderAccountModals
  };
}

export default useNavbar;
