import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setError, setMintingData, setStateData} from "../../redux/auth/actions";
import {useApolloClient, ApolloQueryResult} from "@apollo/client";
import AppGql from "../appollo/AppGql";
import {IMapStateToProps} from "../../types";
import {IMintingData, InitialMintingData} from "../types/mintDataTypes";
import BigNumber from "bignumber.js";
import {getAppControllers} from "../controllers";

const useMintingDataUpdater = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const appState = useSelector((state: IMapStateToProps) => state);
  const {
    authUser: {
      address: addressConnected,
      web3
    },
  } = appState;

  /*
  // @deprecated Based on smart contract.
  const updater = useCallback(async () => {
    console.log(" CH-> useMintingDataUpdater ");

    let error = false;
    let mintingData: IMintingData = InitialMintingData;
    try {
      setLoading(true);
      mintingData = await getAppControllers(web3).contract.getMintingData(addressConnected);
      console.log('  -> Minting data', mintingData);
      dispatch(setMintingData(mintingData));
    } catch (e) {
      console.error(e);
      dispatch(setError(e));
      error = true;
    } finally {
      setLoading(false);
    }
    return (error) ? null : mintingData;
  }, [dispatch, client]);*/

  const updater = useCallback(async () => {
    console.log(" CH-> useMintingDataUpdater ");

    let error = false;
    let mintingDataList: Array<any> = [];
    try {
      setLoading(true);
      let i = 0;
      while (true && i < 10) {
        const res: ApolloQueryResult<any> = await client.query({
          query: AppGql.GetMintingDataList,
          variables: {
            first: AppGql.LIMIT,
            skip: AppGql.LIMIT * i
          },
          fetchPolicy: "no-cache",
        });
        if (res.error) {
          error = true;
          break;
        }

        if (res.data && res.data.s2Tmpasses) {
          mintingDataList = [...mintingDataList, ...res.data.s2Tmpasses];
          if (res.data.s2Tmpasses.length < 1 || res.data.s2Tmpasses.length < AppGql.LIMIT) {
            break;
          }
        } else {
          error = true;
          break;
        }
        i++;
      }

      if (error) {
        console.error("Fetching error to get minting data.");
        dispatch(setError("Fetching error to get minting data."));
      } else {
        if (mintingDataList.length > 0) {
          for (let idx in mintingDataList) {
            mintingDataList[idx].price = new BigNumber(mintingDataList[idx].price);
            mintingDataList[idx].nftSold = parseInt(mintingDataList[idx].S2TMPassSold) || 0;
            mintingDataList[idx].nftTotal = parseInt(mintingDataList[idx].supplyLimit) || 0;
            mintingDataList[idx].mintLimit = parseInt(mintingDataList[idx].mintLimit) || 0;
          }
          dispatch(setStateData({nftPrices: {normal_price: new BigNumber(mintingDataList[0].price || 0)}}));
        }
        dispatch(setMintingData(mintingDataList));
      }
    } catch (e) {
      console.error(e);
      dispatch(setError(e));
      error = true;
    } finally {
      setLoading(false);
    }

    console.log(mintingDataList);
    return (error) ? null : mintingDataList;
  }, [dispatch, client]);

  return {loading, updater}
}

export default useMintingDataUpdater;
