import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";

import './index.scss';

import {NetAddressMap} from "../../dnweb3/constants";
import {APP_NETWORK_ID} from "../../dnweb3/config";
import Roadmap from "../Roadmap";
import SpacePassCard from "../../components/SpacePassCard";
import ImgMoon from "../../assets/images/Moon.png";
import Button from "../../components/Button";
import {isMobile} from 'react-device-detect';
import BuyNFT from "./BuyNFT";


// @ts-ignore
const SMART_CONTRACT_ADDRESS = NetAddressMap[APP_NETWORK_ID]['MASTER'] ?? '';

const Home = () => {
  const history = useHistory();

  return (
    <div className="page-body">
      <div className="container px-6 md:px-14 3xl:px-50">

        <div className="home-top flex flex-wrap flex-row relative w-full mt-22 lg:mt-32">
          <div className="text-white md:max-w-md 3xl:max-w-162_5">
            <h1 className="font-inter text-15 leading-16 md:text-7xl md:leading-20 font-extrabold">The S2TM <br/>Space Pass</h1>
            <div className="text-xl 3xl:text-xl55 leading-10 3xl:leading-11_25 mt-4">
            Are you ready to embark on a mission of a lifetime?
            </div>

            <BuyNFT />

            <div className="mb-12 3xl:mb-20"></div>
          </div>

          <div className="flex w-full 3xl:ml-10 cards-wrap">
              <SpacePassCard className={`z-20 ${isMobile ? 'hidden1' : ''}`} />
            {/*{<SpacePassCard className="transform -rotate-14 z-10 -ml-48 mt-20 hidden lg:block"/>}*/}
          </div>
        </div>

        <div className="flex flex-row relative w-full md:max-w-lg lg:max-w-xl xl:max-w-181_5 mt-18 md:mt-6 lg:mt-40" id="season1">
          <div className="text-white max-w-181_5">
            <h2 className="font-bold text-4xl2 md:text-10_5 3xl:text-10_5 leading-12 md:leading-12_75">// Season 1</h2>
            <p className="text-xl md:text-xl55 leading-loose md:leading-11_25 mt-4">By minting a S2TM Space Pass, you’ll join a fleet of new recruits who are on a mission to stop Nifty and his legion of Wen from collecting the 10 Moons of Ilsendor. Moons that could give Nifty
              the
              power to destroy galaxies, end life on Earth and forever rule the universe. We need you to stop Nifty.</p>
          </div>
          {<img className="absolute moon" src={ImgMoon} width={791} height={791}/>}
        </div>


        <div className="flex flex-wrap flex-row relative w-full mt-20 md:mt-22 lg:mt-24 3xl:mt-47" id="scope">
          <div className="max-w-2xl lg:max-w-full xl:max-w-2xl 3xl:max-w-3xl mx-auto text-white md:text-center">
            <h2 className="font-bold text-4xl2 md:text-10_5 3xl:text-10_5 leading-12 md:leading-12_75">Scope of the Project</h2>
            <p className="text-xl md:text-xl55 leading-loose md:leading-11_25 mt-6 md:mt-8">
              This NFT project is an experience. A story which unfolds and elevates in utility in the coming months and years. With each mission, the lore is further developed and there are special drops to Space Pass holders. As with every mission,
              secrets are sacred. What you see is just the beginning.
            </p>
          </div>
        </div>

        <Roadmap />

      </div>


      {/*<CommunityTool/>*/}
      {/*<TeamPage/>
      <Newsletter/>*/}
    </div>
  );
}
export default Home;
