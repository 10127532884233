import Web3 from "web3";

const ConnectToGanacheInjected = async () => {
    let provider = null;
    provider = new Web3.providers.HttpProvider('http://127.0.0.1:7545');
    return provider;
  }
;

export default ConnectToGanacheInjected;
