import {useEffect, useRef} from 'react'

export default function useBodyScrollbar(hideScrollbar: boolean) {
  useEffect(() => {
    const body: HTMLBodyElement | null = document.querySelector('body');
    if (body) {
      const scrollWidth = window.innerWidth - document.body.clientWidth ;
      if (hideScrollbar) {
        body.classList.add('modal-active');
        body.style.marginRight = scrollWidth + "px";
      } else {
        body.classList.remove('modal-active');
        body.style.marginRight = 'unset';
      }
    }
  }, [hideScrollbar]);
}
