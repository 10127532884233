import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch, useLocation} from 'react-router-dom';
import {SConnectModal} from "../Dialog/SConnectModal";
import {useDispatch, useSelector} from "react-redux";
import {IMapStateToProps} from "../../types";
import {ellipseAddress} from "../../dnweb3/helpers/utilities";
import SAccountModal from "../Dialog/SAccountModal";
import LinkButton from "../LinkButton";
import Button from "../Button";

import ImgMetamask from "../../assets/images/icon/metamask-i.png";
import useNavbar from "../hooks/useNavbar";

interface INavbarProps {
  toggle: any,
  isOpen: boolean
}

export const LogoEle = ({
                          extraCls
                        }: any) => (
  <Link to='/' className={`logo-wrap ${extraCls || ''}`}>
    <img src="/assets/img/logo/logo.png" width={75} height={22}/>
  </Link>
);

export const NAV_LINKS = [
  {
    link: '/#season1',
    title: 'SEASON1'
  },
  {
    link: '/#scope',
    title: 'SCOPE'
  },
  {
    link: '/#blueprint',
    title: 'BLUEPRINT'
  },
];


const Navbar = ({toggle, isOpen}: INavbarProps) => {

  const {
    renderConnectButton,
    renderAccountModals
  } = useNavbar(isOpen);

  return (
    <React.Fragment>
      <nav
        className='header-nav flex justify-between items-center uppercase text-white relative text-sm2 py-9_5 px-6 md:px-16'
        role='navigation'
      >
        <LogoEle/>

        {NAV_LINKS.map((x, ind) =>
          <Link key={x.title} to={x.link} className={`nav px-4 hover:opacity-95 hidden md:block ${ind == 0 ? 'ml-auto' : ''}`}>{x.title}</Link>)}

        {renderConnectButton()}
        {renderAccountModals()}

        <div className='cursor-pointer md:hidden' onClick={toggle}>
          <svg
            className='w-8 h-8'
            fill='#ffffff'
            stroke='#ffffff'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill='#ffffff'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 6h16M4 12h16M4 18h16'
            />
          </svg>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;

