/* AUTH */
export const SET_LOADING_TYPE = "SET_LOADING_TYPE";
export const SET_LOADING = "SET_LOADING";

export const SET_ADDRESS = "SET_ADDRESS";
export const SET_NETWORKID = "SET_NETWORKID";
export const SET_CONNECT_TYPE = "SET_CONNECT_TYPE";
export const SET_ERROR = "SET_ERROR";
export const SET_POOL_LIST = "SET_POOL_LIST";
export const SET_CURRENT_APY = "SET_CURRENT_APY";
export const SET_CURRENT_APR = "SET_CURRENT_APR";
export const SET_TOTAL_USER_INFO = "SET_TOTAL_USER_INFO";
export const SET_USER_POOL_LIST = "SET_USER_POOL_LIST";
export const SET_START_BLOCK = "SET_START_BLOCK";
export const SET_INIT_DATA = "SET_INIT_DATA";
export const SET_STATE_DATA = "SET_STATE_DATA";
export const SET_LOGGED_IN = "SET_LOGGED_IN";

// NFTs
export const SET_SELECTED_NFT_ID = "SET_SELECTED_NFT_ID";
export const SET_NFT_LIST = "SET_NFT_LIST";
export const SET_USER_NFT_ID_LIST = "SET_USER_NFT_ID_LIST";

// Canine cartels
export const SET_MINTING_DATA = "SET_MINTING_DATA";

export * from "./auth/actions";

