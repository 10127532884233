import React, {useEffect, useMemo, useState} from 'react';

import "./index.scss";
import ImgMars from "../../assets/images/Mars.png";
import ImgMoon1 from "../../assets/images/S2TM_MoonSilhouette-1.png";
import ImgMoon2 from "../../assets/images/S2TM_MoonSilhouette-2.png";
import ImgMoon3 from "../../assets/images/S2TM_MoonSilhouette-3.png";

const Roadmap = () => {

  return (
    <div className="relative w-full mt-20 sm:mt-32 md:mt-22 lg:mt-32 text-white roadmap font-normal" id="blueprint">
      <h1 className="font-inter text-15 sm:text-7xl leading-16 sm:leading-20 font-extrabold mb-20 sm:mb-25">The <br/>Space Map</h1>
    
      <div className="roadmap-items">
        <div className="max-w-5xl mb-20">
          <p className="text-xl md:text-xl55 leading-loose md:leading-11_25 mt-4">
            The Space Map is our version of a road map. Composed of foundational elements, each monthly grouping is filled with utility and perks for recruits. As the storyline unfolds, S2TM Space Pass holders are rewarded with activations that prepare them for the next mission. Assets that are needed to combat Nifty and the Legion of Wen.
          </p> 
        </div>

        <div className="roadmap-item max-w-5xl mb-20 sm:mb-37_5">
          <h2 className="text-4xl2 sm:text-10_5 leading-12 sm:leading-12_75 font-bold">September - November</h2>
          <p className="text-xl sm:text-xl55 leading-loose sm:leading-11_25 mt-6">
            We have lift off! The S2TM Crew and recruits leave earth and head into deep space.          
          </p>
          <ul className="mt-8 text-xl sm:text-xl55 leading-loose sm:leading-11_25 list-disc pl-4">
            <li>Mission dispatches</li>
            <li>Historic voyage drop</li>
            <li>Special artist integration for mission acceleration</li>
          </ul>
        </div>

        <div className="roadmap-item mb-20 sm:mb-37_5 md:max-w-md lg:max-w-lg xl:max-w-2xl">
          <h2 className="text-10_5 leading-12_75 font-bold">December - January</h2>
          <p className="text-xl sm:text-xl55 leading-loose sm:leading-11_25 mt-6">
            Mission #1: Simia, the first moon and home to Fire Monkeys.
          </p>
          <ul className="mt-8 text-xl sm:text-xl55 leading-loose sm:leading-11_25 list-disc pl-4">
            <li>Mission dispatches</li>
            <li>Mission accleration: special artist integration</li>
            <li>Fire Monkey avatars and specialized merch</li>
          </ul>
          <img className="absolute hidden md:block img-mar" src={ImgMars} width={587} height={587} />
        </div>

        <div className="roadmap-item mb-20 sm:mb-37_5 md:max-w-md lg:max-w-lg xl:max-w-2xl">
          <h2 className="text-10_5 leading-12_75 font-bold">February - March</h2>
          <p className="text-xl sm:text-xl55 leading-loose sm:leading-11_25 mt-6">
            Mission #2: Zolturia, the second moon and home to Zolturians.
          </p>
          <ul className="mt-8 text-xl sm:text-xl55 leading-loose sm:leading-11_25 list-disc pl-4">
            <li>Mission dispatches</li>
            <li>Mission velocity: special artist integration</li>
            <li>Zolturian avatars and specialized merch</li>
          </ul>
          <img className="absolute hidden md:block img1" src={ImgMoon1} width={929} height={929} />
        </div>


        <div className="roadmap-item mb-20 sm:mb-37_5 md:max-w-md lg:max-w-lg xl:max-w-2xl">
          <h2 className="text-10_5 leading-12_75 font-bold">April - May</h2>
          <p className="text-xl sm:text-xl55 leading-loose sm:leading-11_25 mt-6">
            Mission #3: Loborio, the third moon and home to the Ice Wolves.
          </p>
          <ul className="mt-8 text-xl sm:text-xl55 leading-loose sm:leading-11_25 list-disc pl-4">
            <li>Mission dispatches</li>
            <li>Mission surge: special artist integration</li>
            <li>Ice Wolf avatars and specialized merch</li>
          </ul>
          <img className="absolute hidden md:block img2" src={ImgMoon2} width={929} height={929} />
        </div>


        <div className="roadmap-item mb-20 sm:mb-37_5 lg:mb-25 md:max-w-md lg:max-w-lg xl:max-w-2xl">
          <h2 className="text-10_5 leading-12_75 font-bold">June</h2>
          <p className="text-xl sm:text-xl55 leading-loose sm:leading-11_25 mt-6">
            Mission #4: Omrow, a wasteland portal moon.
            This is the end of Season 1 and the beginning of Season 2.
          </p>
          <ul className="mt-8 text-xl sm:text-xl55 leading-loose sm:leading-11_25 list-disc pl-4">
            <li>Mission dispatches</li>
            <li>Starship building</li>
            <li>Space Pass role activation</li>
            <li>Mission acceleration: special artist integration</li>
          </ul>
          <img className="absolute  hidden md:block img3" src={ImgMoon3} width={929} height={929} />
        </div>

        <i className="text-xl 3xl:text-xl55 leading-10 3xl:leading-11_25 mt-4">
          The S2TM Space Pass is an evolving project and is subject to change. Ownership of S2TM products do not convey ownership of Shot2TheMoon (S2TM), the company, including but not limited to the rights of any potential future uses of the Intellectual Property unless otherwise specified. This is not an investment product and please do not treat it as one. Please do your own research and act responsibly.
        </i>

      </div>
    </div>
  );
}
export default Roadmap;
