import {
  SET_ADDRESS,
  SET_NETWORKID,
  SET_CONNECT_TYPE,
  SET_ERROR,
  SET_POOL_LIST,
  SET_CURRENT_APY,
  SET_TOTAL_USER_INFO,
  SET_USER_POOL_LIST,
  SET_START_BLOCK,
  SET_CURRENT_APR,
  SET_INIT_DATA,
  SET_LOGGED_IN,
  SET_STATE_DATA,
  SET_LOADING,
  SET_LOADING_TYPE,
  SET_NFT_LIST,
  SET_USER_NFT_ID_LIST,
  SET_SELECTED_NFT_ID, SET_MINTING_DATA,
} from '../actions';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: {loading}
});

export const setLoadingType = (loadingType) => ({
  type: SET_LOADING_TYPE,
  payload: {loadingType}
});

export const setAddress = (address) => ({
  type: SET_ADDRESS,
  payload: {address}
});

export const setNetworkId = (networkId) => ({
  type: SET_NETWORKID,
  payload: {networkId}
});

export const setConnectType = (connectType) => ({
  type: SET_CONNECT_TYPE,
  payload: {connectType}
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: {error}
});

export const setPoolList = (data) => ({
  type: SET_POOL_LIST,
  payload: {data}
});

export const setCurrentAPY = (data) => ({
  type: SET_CURRENT_APY,
  payload: {data}
});

export const setCurrentAPR = (data) => ({
  type: SET_CURRENT_APR,
  payload: {data}
});

export const setUserPoolList = (data) => ({
  type: SET_USER_POOL_LIST,
  payload: {data}
});

export const setUserTotalInfo = (data) => ({
  type: SET_TOTAL_USER_INFO,
  payload: {data}
});

export const setStartBlock = (data) => ({
  type: SET_START_BLOCK,
  payload: {data}
});

export const setInitData = () => ({
  type: SET_INIT_DATA,
  payload: null
});

export const setStateData = (data) => ({
  type: SET_STATE_DATA,
  payload: data
});

export const setLoggedIn = (status) => ({
  type: SET_LOGGED_IN,
  payload: status
});

/**
 * NFT List
 * @param status
 * @returns {{payload: *, type: string}}
 */
export const setNftList = (nftTypeId) => ({
  type: SET_NFT_LIST,
  payload: nftTypeId
});

export const setUserNftIdList = (userNftTypeIds) => ({
  type: SET_USER_NFT_ID_LIST,
  payload: userNftTypeIds
});

export const setSelectedNftId = (data) => ({
  type: SET_SELECTED_NFT_ID,
  payload: data
});

export const setMintingData = (data) => ({
  type: SET_MINTING_DATA,
  payload: data
});

