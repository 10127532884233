export const APP_API = process.env.REACT_APP_API;
export const APP_MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL;
export const APP_TILE_IMAGE = process.env.REACT_APP_MEDIA_BASE_URL + '/tiles';

export const priceEthPerBlock = 1;

export const SOCIAL_URL_TELEGRAM = '';
export const SOCIAL_URL_TWITTER = 'https://twitter.com/Shot2TheMoon';
export const SOCIAL_URL_INSTAGRAM = '';
export const SOCIAL_URL_OPENSEA = '';
export const SOCIAL_URL_DISCORD = 'https://discord.gg/Shot2TheMoon';
export const SOCIAL_URL_REDDIT = '';

export const OPENSEA_BUYING_URL = 'https://opensea.io/collection/s2tm-s1';

