import {GANACHE_CHAIN_ID, MAINNET_CHAIN_ID, RINKEBY_CHAIN_ID} from "./index";

export const NetAddressMap = {
  [GANACHE_CHAIN_ID]: {
    MASTER: '0x47F8b0B7fA44b15Da54Fdf89d28ed04282DbAa8B',
  },
  [RINKEBY_CHAIN_ID]: {
    MASTER: '0xB5D31a642D1d55eF467526dAF4Db0a693bbf1569',
  },
  [MAINNET_CHAIN_ID]: {
    MASTER: '0xf1d13c4045a345df643e930f3c1a041262e9a143',
  },
};

