import React, {useMemo} from "react";
import "./index.scss";

/*import ImgSpaceShip from "../../assets/images/spaceship.gif";*/
import ImgSpaceShip from "../../assets/images/spaceship.png";
import ImgCardBg from "../../assets/images/Card-no-space2x.png";
import ImgCard from "../../assets/images/Card-s2x.png";
import {bnToDec, ellipseAddress, nf, nf_i} from "../../dnweb3/helpers/utilities";
import {useSelector} from "react-redux";
import {IMapStateToProps} from "../../types";
import {IMintingData, InitialMintingData} from "../../dnweb3/types/mintDataTypes";

interface ISpacePassCard {
  className?: string
  type?: string
  address?: string
  tokenId?: string | undefined
}

const SpacePassCard = ({
                         className,
                         type,
                         address,
                         tokenId,
                       }: ISpacePassCard) => {

  /*const {
    authUser: {
      mintingDataList
    }
  } = useSelector((state: IMapStateToProps) => state);

  const mintingData: IMintingData = useMemo(() => {
    if (mintingDataList) {
      return mintingDataList[0];
    }
    return InitialMintingData;
  }, [mintingDataList]);

  const {
    nftTotal,
    price: mintPrice,
  } = mintingData;*/

  return (
    type == 'real' ?
      <div className={`space-pass-card2 text-sm2 relative leading-6 text-white mx-auto self-start overflow-hidden ${className || ''}`} style={{maxWidth: 359}}>
        <img className="space-card-bg" src={ImgCard} width={359} height={266}/>

        <div className="max-w-23_5 absolute top-0 right-0 m-6">
          <img className="rounded-lg transform scale-90" src={ImgSpaceShip}/>
        </div>
        <div className="absolute bottom-0 left-0 m-8">
          <div className="font-space font-semibold mb-2"> {tokenId && `#${nf(tokenId)}`}</div>
          <div className="font-space font-semibold">{ellipseAddress(address, 15, 5)}</div>
        </div>
      </div>
      :
      <div className={`space-pass-card text-sm2 leading-6 text-white self-start rounded-4 md:rounded-6 overflow-hidden ${className || ''}`}>
        <img className="space-card-bg" src={ImgCardBg} width={497} height={368}/>

        <div className="max-w-23_5 md:max-w-30 absolute top-0 right-0 m-8 md:m-12">
          <img className="rounded-lg" src={ImgSpaceShip}/>
        </div>
        <div className="absolute bottom-0 left-0 m-8 md:m-12">
          <div className="font-space font-semibold mt-5 md:mt-7 mb-2_5 md:mb-4">Sept 22nd, 1:45pm EST</div>
          {/*<div className="font-space font-semibold">{nf_i(nftTotal)} passes {mintPrice.isGreaterThan(0) && ` - ${bnToDec(mintPrice)} ETH`}</div>*/}
          <div className="font-space font-semibold">{nf_i(5000)} passes {`0.05 ETH`}</div>
        </div>
      </div>
  );
}

export default SpacePassCard;
