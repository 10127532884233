import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import "./BuyNFT.scss";
import Button from "../../components/Button";
import {useLocation} from "react-router-dom";
import {setLoading, setLoadingType} from "../../redux/auth/actions";
import {LOADING_TYPE_BUYING, LOADING_TYPE_FETCH_POOL_DATA, MSG_NO_CURRENCY, MSG_SELECT_BINANCE} from "../../dnweb3/constants";
import {useDispatch, useSelector} from "react-redux";
import {IMapStateToProps} from "../../types";
import {NotificationManager} from 'react-notifications';
import BigNumber from "bignumber.js";
import {getAppControllers} from "../../dnweb3/controllers";
import {FacebookShareButton, TwitterShareButton} from "react-share";

import ImgSuccess from "../../assets/images/icon/checkout-success.svg";
import ImgFacebook from "../../assets/images/icon/facebook-dark.svg";
import ImgTwitter from "../../assets/images/icon/twitter-red.svg";
import {bnToDec, decToBn, nf, nf_i} from "../../dnweb3/helpers/utilities";
import SLoadingWrap from "../../components/SLoadingWrap";
import ImgCard from "../../assets/images/Card-s2x.png";
import ImgClose from "../../assets/images/icon/Close.svg";

import {IMintingData, InitialMintingData} from "../../dnweb3/types/mintDataTypes";

import Countdown from 'react-countdown';
import {scrollToElement} from "../../dnweb3/helpers/utilities";
import LinkButton from "../../components/LinkButton";
import {OPENSEA_BUYING_URL} from "../../config";
import _ from 'lodash';
import useBodyScrollbar from "../../dnweb3/hooks/useBodyScrollbar";
import AppApi from "../../dnweb3/appapi";
import {typedSignatureHash} from "eth-sig-util/dist";
import useMintingDataUpdater from "../../dnweb3/hooks/useMintingDataUpdater";
import SpacePassCard from "../../components/SpacePassCard";

const DISABLE_COLLAPSIBLE_WIDTH = 768;
const STEP_CHECKOUT = 1;
const STEP_SUCCESS = 2;

interface Value {
  formatted: {
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
  }
  completed: any;
}

const ButtonBuy = (props: any) => {
  const {packageQty, unitPrice, onClick} = props;

  return (
    <Button className="btn-white mt-6 lg:py-5 xs:px-4 sm:px-10 md:px-0 lg:px-0 xl:px-0 w-32 sm:w-40 md:w-32 lg:w-40 xl:w-22" onClick={onClick}>
      <h4 className="text-xl font-black uppercase">BUY {packageQty}</h4>
      {/*<div className="text-sm leading-0_875 font-normal mt-2">{unitPrice}Ξ each</div>*/}
    </Button>
  );
}

const BuyNFT = () => {
  const dispatch = useDispatch();

  const width = document.body.clientWidth;
  const location = useLocation();
  const [collapsible, setCollapsible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [qty, setQty] = useState(1);

  const audioRef = useRef<any>();
  const [stop, setStop] = useState<boolean>(true);

  const [lastTokenId, setLastTokenId] = useState();
  const [step, setStep] = useState<number>(-1);
  const {loading: loadingMintingData, updater: updateMintingData} = useMintingDataUpdater();

  const {
    authUser: {
      loading,
      loadingType,
      connectType,
      networkId: curNetworkId,
      address,
      loggedIn,
      web3,
      mintingDataList
    }
  } = useSelector((state: IMapStateToProps) => state);

  const mintingData: IMintingData = useMemo(() => {
    if (mintingDataList) {
      return mintingDataList[0];
    }
    return InitialMintingData;
  }, [mintingDataList]);

  const {
    saleActive,
    nftSold,
    nftTotal,
    mintLimit,
    price: mintPrice,
  } = mintingData;

  console.log(mintingData);
  // const canineSold = '10000';
  // const saleActive = true;

  const getPackagePrice = useCallback(async (packageQty: number) => {
    return await getAppControllers(web3).contract.getPrice(packageQty);
  }, [web3]);

  useEffect(() => {
    setCollapsible(width < DISABLE_COLLAPSIBLE_WIDTH);
  }, []);

  useBodyScrollbar(step > 0 || loading && loadingType > 0);

  const onCloseHandler = () => {
    if (step == STEP_SUCCESS) {
      setStep(-1);
    }
  }

  const onClickMint = async (packageQty?: number) => {
    console.log("buying started.", packageQty);
    const buyingQty = packageQty ? packageQty : qty;
    if (!loggedIn || !address) {
      NotificationManager.info("Please connect to metamask!");
      return;
    }
    if (buyingQty < 1) {
      NotificationManager.info("Please file quantity correctly!");
      return;
    }

    dispatch(setLoadingType(LOADING_TYPE_BUYING));
    const balance: BigNumber = await getAppControllers(web3).contract.getUserBalance(address);
    const priceTotal: BigNumber = await getPackagePrice(buyingQty);
    console.log('Balance:', balance.toString(), 'priceTotal:', priceTotal.toString());

    if (priceTotal.lte(balance)) {
      // todo dev code.
      /*refreshMintedMetadata(address, 3, {
        "blockHash": "0xfa28f7862bd3edc8c03d29db21b7c535bc23742e9b96ca6cc69be0aef5ecefde",
        "blockNumber": 9163471,
        "contractAddress": null,
        "cumulativeGasUsed": 4380116,
        "effectiveGasPrice": "0x3b9aca08",
        "from": "0xd00c5a907a0c56261deecf74110436e24585b122",
        "status": true,
        "to": "0xf8a553c1b4fcf3eb0c067ed8666168c3704c020d",
        "transactionHash": "0x629959e7ba373e6bd289d938136c80ae11a795c43fe95d2e6a154fb07646eaa2"
      });
      dispatch(setLoading(false));
      return;*/

      /*setTimeout(() => {
        const res = {
          "blockHash": "0xf256410d49a3c4c6db901b945c0c638d4f8fe81b5ee39523eae24c5cd93f3c19",
          "blockNumber": 9321587,
          "contractAddress": null,
          "cumulativeGasUsed": 3437243,
          "effectiveGasPrice": "0x3b9aca08",
          "from": "0xd00c5a907a0c56261deecf74110436e24585b122",
          "gasUsed": 101457,
          "logsBloom": "0x00002000000000000000000000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000008000000000000000000200000000002000000800000000000020000000000000000000800000000000000000000000010000000000000000401000000000000000000004000000000000000000000000000000020000000000000000000000000000001000000000000010000000000010000000000020002000000000000000000000000000000000000000000000000000020000400000000000000000000000000000000004000000000000000000000000000",
          "status": true,
          "events": {
            "PassMinted": [
              {
                "address": "0x9EA905C5dEd877d2fCb8c30E9b5d39E670828d8B",
                "blockHash": "0xf256410d49a3c4c6db901b945c0c638d4f8fe81b5ee39523eae24c5cd93f3c19",
                "blockNumber": 9321587,
                "returnValues": {
                  "0": "0xD00c5a907A0c56261dEECf74110436E24585B122",
                  "1": "327",
                  "2": "",
                  "_user": "0xD00c5a907A0c56261dEECf74110436E24585B122",
                  "_tokenId": "327",
                  "_tokenURI": ""
                },
              },
              {
                "address": "0x9EA905C5dEd877d2fCb8c30E9b5d39E670828d8B",
                "blockHash": "0xf256410d49a3c4c6db901b945c0c638d4f8fe81b5ee39523eae24c5cd93f3c19",
                "blockNumber": 9321587,
                "logIndex": 80,
                "returnValues": {
                  "0": "0xD00c5a907A0c56261dEECf74110436E24585B122",
                  "1": "328",
                  "2": "",
                  "_user": "0xD00c5a907A0c56261dEECf74110436E24585B122",
                  "_tokenId": "328",
                  "_tokenURI": ""
                },
              }
            ]
          }
        };

        if (res && res.status) {
          console.log("Array checking: ", _.isArray(res.events.PassMinted));
          const tokenId = _.isArray(res.events.PassMinted) ?
            res.events.PassMinted[0].returnValues._tokenId : (res.events.PassMinted as any).returnValues._tokenId;
          setLastTokenId(tokenId);
        }

        setStep(STEP_SUCCESS);
        dispatch(setLoading(false));
      }, 600);
      return;*/

      getAppControllers(web3).contract.buyMultiple(address, buyingQty, priceTotal).then(async res => {
        console.log(" ===> Buying Result : ", res);
        if (res && res.status) {
          const tokenId = _.isArray(res.events.PassMinted) ?
            res.events.PassMinted[0].returnValues._tokenId : res.events.PassMinted.returnValues._tokenId;
          setLastTokenId(tokenId);
        }
        await updateMintingData();
        setStep(STEP_SUCCESS);
      }).catch(reason => {
        console.error(reason);
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      NotificationManager.info(MSG_NO_CURRENCY);
      dispatch(setLoading(false));
    }
  };

  const changeQty = (dir: number) => {
    if (!saleActive) return;
    setQty((prev) => {
      let newQty = prev + dir;
      if (newQty < 1) newQty = 1;
      if (newQty > mintLimit) newQty = mintLimit;
      return newQty;
    });
  }

  const onChangeQty = (e: any) => {
    let newQty = parseInt(e.target.value);
    if (isNaN(newQty) || newQty < 1) newQty = -1;
    if (newQty > mintLimit) newQty = mintLimit;
    setQty(newQty);
  }

  const refreshMintedMetadata = (address: string, buyingQty: number, res: any) => {
    const dataParam = {
      address,
      buyingQty,
      res: {
        blockHash: res.blockHash,
        blockNumber: res.blockNumber,
        from: res.from,
        transactionHash: res.transactionHash
      }
    };

    AppApi.post('/metadata/update-minted', dataParam).then(res => {
    }).catch(reason => {
      const msg = AppApi.getResponseErrorMsg(reason);
      console.error(msg);
    });
  }

  const renderCountDown = (value: Value) => {
    return (
      <div className="flex max-w-64 w-full justify-between">
        {value.formatted.days !== '00' ? <>
          <div className="hour flex flex-col items-center">
            <p className="counter-time font-stinger_fit font-bold text-3xl">{value.formatted.days}</p>
            <p className="font-inter text-base leading-7">Days</p>
          </div>
          <span className="counter-time font-stinger_fit font-bold text-3xl">:</span></> : ''}
        <div className="hour flex flex-col items-center">
          <p className="counter-time font-stinger_fit font-bold text-3xl">{value.formatted.hours}</p>
          <p className="font-inter text-base leading-7">Hours</p>
        </div>
        <span className="counter-time font-stinger_fit font-bold text-3xl">:</span>
        <div className="minute flex flex-col items-center">
          <p className="counter-time font-stinger_fit font-bold text-3xl">{value.formatted.minutes || 0}</p>
          <p className="font-inter text-base leading-7">Mins</p>
        </div>
        <span className="counter-time font-stinger_fit font-bold text-3xl">:</span>
        <div className="second flex flex-col items-center">
          <p className="counter-time font-stinger_fit font-bold text-3xl">{value.formatted.seconds || 0}</p>
          <p className="font-inter text-base leading-7">Secs</p>
        </div>
      </div>
    );
  };


  const renderCountDownBody = () => {
    return (
      <>
        <div className="mb-2 font-bold">Coming soon!</div>
        <h3>Mint your own Space pass</h3>
        <div className="my-6 border-b-default border-white"></div>
        <div>Launching on 22nd September, 2021 (1:45pm EST)</div>
        <div className="mint-wrap my-6">
          <div className="flex">
            <Countdown
              date={'2021-09-22T13:45:00-04:00'}
              zeroPadTime={2}
              renderer={renderCountDown}
            />
          </div>
        </div>
      </>
    );
  }

  const renderByStep = () => {
    switch (step) {
      case STEP_SUCCESS:
        // let shareTitle = `Just minted ${nf(qty, 0)} Space Pass${qty > 1 ? 'es' : ''}! https://.dog\n\nA pack of ${nf_i(nftTotal)}} of the craziest mutts to ever run these streets.\n\n`;
        return (
          <>
            <img className="absolute right-0 top-0 mr-6 mt-6 cursor-pointer" src={ImgClose} onClick={onCloseHandler}/>
            {/*<div className="text-center"><img className="mb-4 mx-auto" src={ImgSuccess} width={64} height={64}/></div>*/}
            <SpacePassCard type={'real'} address={address} tokenId={lastTokenId}/>
            <h4 className="text-xl55 font-space text-center mt-6">
              Welcome aboard!
            </h4>
            {/*<div className="mt-6 text-center shares space-x-4">
              <TwitterShareButton
                className="space-x-2 btn btn-default text-black"
                title={shareTitle}
                url={`https://caninecartel.dog`}
                hashtags={['Canine Cartel']}
                via={``}
                resetButtonStyle={false}
              >
                <img className="inline-block" src={ImgTwitter} width={21} height={18}/>
                <span className="text-red-600 font-black uppercase align-middle">Twitter</span>
              </TwitterShareButton>

              <FacebookShareButton
                url={"https://musee.art"}
                className="space-x-2"
                quote={shareTitle}
                hashtag={'Musée'}
              >
                <img src={ImgFacebook} width={20} height={20}/>
                <span>Facebook</span>
              </FacebookShareButton>
            </div>*/}
          </>
        );
        break;
    }
  }

  const soldOut = parseInt(`${nftSold || 0}`) >= (nftTotal || 5000);

  return (
    <div className="section flex flex-wrap xl:flex-nowrap items-start">
      <div className="lg:flex xl:block lg:space-x-12 xl:space-x-0 sm:min-w-100 xl:min-w-114_5">
        <div className="mt-12 p-0 lg:p-8 lg:pl-0 lg:mt-0 xl:mt-12 sm:min-w-100 xl:min-w-114_5" id="buy">
          {(saleActive && !soldOut) ? <SLoadingWrap
            className="modal-content text-left items-center justify-center"
            maskCls={'parent'}
            loading={loadingMintingData}
          >
            <div className="flex flex-wrap items-center">
              <div className="input-wrap flex rounded-default space-x-1 mr-6 lg:mr-6 xl:mr-8 mt-6">
                <div className={`control ${saleActive ? '' : 'disabled'}`} onClick={() => changeQty(-1)}>-</div>
                <input type="text" disabled={!saleActive} className="bg-white" value={qty > 0 ? qty : ''} onChange={onChangeQty}/>
                <div className={`control ${saleActive ? '' : 'disabled'}`} onClick={() => changeQty(1)}>+</div>
              </div>
              <Button
                disable={!saleActive}
                className="btn btn-primary uppercase text-4_25 leading-4 3xl:leading-4 inline-block mt-6 py-6"
                onClick={() => onClickMint()}
              >Mint now{mintPrice.isGreaterThan(0) && ` - ${bnToDec(mintPrice)}Ξ`}</Button>
            </div>
            <div className="text-xl my-4">{nf_i(nftSold) || '0'} / {nf_i(nftTotal)} Space Passes sold</div>
            {<p className="text-xs italic">You can only mint 10 NFTs in one transaction.</p>}
          </SLoadingWrap> : (
            (!soldOut) ? renderCountDownBody() :
              <>
                <h3>We’ve sold out!</h3>
                <div className="mt-3 text-base leading-7">The initial sale of {nf_i(nftTotal)} NFTs has sold out. To get your NFT, check out the collection on OpenSea.</div>
                <div className="flex mt-6">
                  <LinkButton
                    disable={saleActive}
                    className="btn-white uppercase"
                    target={'_blank'}
                    linkTo={OPENSEA_BUYING_URL}
                    external
                  >Buy a Space Pass on opensea</LinkButton>
                </div>
              </>
          )}
        </div>
      </div>

      <div
        className={`modal-checkout modal ${(step > 0) ? '' : 'hidden'} flex flex-col fixed w-screen min-h-full top-0 left-0 flex items-center shadow-lg justify-center z-50`}
      >
        <div className="modal-overlay absolute w-screen h-full bg-black opacity-50 pointer-events-none" onClick={onCloseHandler}></div>
        <div className="modal-container max-h-screen90 bg-white w-11/12 md:max-w-md mx-auto shadow-lg z-50 overflow-y-auto relative">
          <div className="modal-content pt-14 pb-10 sm:pt-14 sm:pb-14 text-left px-8 sm:px-14 items-center justify-center text-black">
            {renderByStep()}
          </div>
        </div>
      </div>
    </div>
  );
}
export default BuyNFT;
