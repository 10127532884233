import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import ReactLoading from "react-loading";

import {LOADING_TYPE_BUYING} from "../../dnweb3/constants";

import {IMapStateToProps} from "../../types";
import useBodyScrollbar from "../../dnweb3/hooks/useBodyScrollbar";



interface ISLoadingModalProps {
  isOpen: boolean
}

const SLoadingModal = ({
                         isOpen
                       }: ISLoadingModalProps) => {
  const modalRef: any = useRef();
  const loading = useSelector((state: IMapStateToProps) => state.authUser.loading);
  const loadingType = useSelector((state: IMapStateToProps) => state.authUser.loadingType);

  useBodyScrollbar(isOpen || (loading && loadingType > 0));

  const renderBody = useCallback(() => {
    switch (loadingType) {
      case LOADING_TYPE_BUYING:
        return (
          <>
            <h3 className="text-xl55 font-bold text-center text-black">
              Hang tight, we’re minting your S2TM Space Pass.
            </h3>
          </>
        );
    }
  }, [loadingType]);

  return (
    <>
      {<div className={`modal ${isOpen || (loading && loadingType > 0) ? 'pointer-events-auto' : 'opacity-0'} pointer-events-none fixed w-screen h-full top-0 left-0 flex items-center  justify-center z-50`} ref={modalRef}>
        <div className="modal-overlay absolute w-screen h-full bg-black opacity-50"></div>
        <div className="modal-container bg-white max-h-screen90 w-11/12 max-w-md mx-auto shadow-lg z-50 overflow-y-auto font-space">
          <div className="modal-content text-left items-center justify-center px-14 pt-18 pb-14">
            <ReactLoading className="mx-auto mb-10" type={"spinningBubbles"} color={"#000"} height={56} width={56}/>
            {renderBody()}
          </div>
        </div>
      </div>}
    </>
  );
}

export default SLoadingModal;
