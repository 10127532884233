import React, {useState} from 'react';
import ReactLoading, {LoadingType} from "react-loading";
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  &.full{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.09);
  }
`;

type MaskType = "full" | "parent";

interface ISLoadingWrap {
  loading: boolean;
  iconType?: LoadingType;
  maskType?: MaskType;
  iconWidth?: number;
  iconHeight?: number;
  iconColor?: string;

  className?: string;
  maskCls?: string;
  toggle?: any;
  loadingTitle?: string
  loadingTitleClass?: string

  children?: any;
}

const SLoadingWrap = ({
                        loading,
                        iconType,
                        iconWidth,
                        iconHeight,
                        iconColor,

                        className,
                        maskCls,
                        children,
                        loadingTitle,
                        loadingTitleClass,
                      }: ISLoadingWrap) => {

  return (
    <div className={`relative ${className || ''}`}>
      {loading && (
        <StyledDiv className={`loading-mask ${maskCls || 'full'}`}>
          <ReactLoading type={`${iconType ? iconType : "spinningBubbles"}`} color={`${iconColor || '#D054D0'}`} height={iconHeight || 50} width={iconWidth || 50}/>
          {loadingTitle && <div className={loadingTitleClass || ''}>{loadingTitle}</div>}
        </StyledDiv>
      )}
      {children}
    </div>
  );
}

export default SLoadingWrap;
