// apollo testing
import {gql} from "@apollo/client";

const LIMIT = 1000;
const LIMIT_HOLDERS = 1000;

/**
 * @deprecated
 */
const getIdsMinted = gql`
    query getIdsMinted ($skip: Int){
        holderEntities (first: ${LIMIT}, skip: $skip){
            id
            tokenId
        }
    }
  `;

/**
 * @deprecated
 */
const GetTokenIdsByHolder = gql`
    query GetTokenIdsByHolder ($account: String!, $skip: Int) {
      holderEntities(first: ${LIMIT_HOLDERS}, skip: $skip, where: {newHolder: $account}) {
        id
        tokenId
        timestamp
      }
    }
  `;

/**
 * @deprecated
 */
const GetHoldersByTokenId = gql`
    query GetHoldersByTokenId ($id: String!) {
      holderEntities(where: {id: $id}) {
        id
        oldHolder
        newHolder
        tokenId
        timestamp
      }
    }
  `;


const GetMintingDataList = gql`
    query getS2Tmpasses ($skip: Int) {
      s2Tmpasses(first: ${LIMIT}, skip: $skip) {
        id
        owner
        saleActive
        price
        mintLimit
        supplyLimit
        baseURI
        S2TMPassSold
        reserved
      }
    }
  `;


const GetRegisters = gql`
  query($address: String, $first:Int, $skip: Int, $sortDir:String, $sortBy:String) {
    registers(first: $first, skip: $skip, where: {owner: $address}, orderDirection:$sortDir, orderBy: $sortBy) {
      id
      name
      owner
      style
    }
  }
`;

const AppGql = {
  LIMIT,
  GetMintingDataList: GetMintingDataList,

  LIMIT_HOLDERS,
  getIdsMinted,
  GetTokenIdsByHolder,
  GetHoldersByTokenId,
  GetRegisters
};

export default AppGql;
