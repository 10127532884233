import React, {useState, useEffect} from 'react';
import {Link, useRouteMatch, useLocation} from 'react-router-dom';
import {SConnectModal} from "../Dialog/SConnectModal";
import {useDispatch, useSelector} from "react-redux";
import {IMapStateToProps} from "../../types";
import {ellipseAddress} from "../../dnweb3/helpers/utilities";
import SAccountModal from "../Dialog/SAccountModal";
import Button from "../Button";
import ImgMetamask from "../../assets/images/icon/metamask-i.png";
import useBodyScrollbar from "../../dnweb3/hooks/useBodyScrollbar";
import useNavbar from "../hooks/useNavbar";
import {LogoEle, NAV_LINKS} from "./Navbar";


interface IDropdownProps {
  isOpen: any,
  toggle: any,
}

const NavbarMobile = ({isOpen, toggle}: IDropdownProps) => {
  const {
    renderConnectButton,
    renderAccountModals
  } = useNavbar(isOpen);

  useBodyScrollbar(isOpen);

  return (
    <div
      className={
        isOpen
          ? 'header-nav-modal flex flex-col text-center items-center text-sm2 text-white bg-black fixed w-full h-full top-0 left-0 z-50'
          : 'hidden'
      }
      style={{padding: '103px 0 40px', overflowY: 'auto'}}
    >
      <div className="flex items-center justify-between absolute top-0 left-0 w-full py-9_5 px-6">
        <LogoEle />

        {renderConnectButton()}
        {renderAccountModals()}

        <div className='cursor-pointer' onClick={toggle}>
          <svg
            className="w-8 h-8"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>

      {NAV_LINKS.map(x => <Link key={x.title} to={x.link} className='nav my-4 hover:opacity-95 lg:hidden'>{x.title}</Link>)}
    </div>
  );
};

export default NavbarMobile;
